<template>
    <div class="flex mx-5 my-5">
        <SideBar :btn-text="'+ 新增資料'" @clickBtn="add" />
        <div class="w-full overflow-x-auto bg-white rounded-lg shadow-md">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>標題</th>
                        <th style="width:100px'">網址</th>
                        <th>描述</th>
                        <th>查看</th>
                        <th>刪除</th>
                    </tr>
                </thead>
                <!-- <draggable v-model="datas" tag="tbody" @change="onSort"> -->
                <tbody>
                    <tr
                        v-for="(item, index) in datas"
                        :key="index"
                        class="text-center transition duration-200"
                    >
                        <td>
                            {{
                                item.title.length > 10
                                    ? item.title.substring(0, 10) + "..."
                                    : item.title
                            }}
                        </td>
                        <td class="break-all max-w-[100px]">
                            {{
                                !$isEmpty(item.url)
                                    ? item.url.substring(0, 10) + "..."
                                    : item.url
                            }}
                        </td>
                        <td>
                            {{
                                item.description !== null &&
                                item.description.length > 10
                                    ? item.description.substring(0, 10) + "..."
                                    : item.description
                            }}
                        </td>
                        <td
                            v-permission="['read']"
                            class="text-blue-500 cursor-pointer"
                            @click="
                                showDetailDialog = true;
                                detailData = item;
                            "
                        >
                            <i class="fas fa-external-link-alt" />
                        </td>
                        <td
                            v-permission="['delete']"
                            class="cursor-pointer"
                            @click="
                                showDialog = true;
                                deleteId = item.id;
                            "
                        >
                            <i class="fas fa-trash" />
                        </td>
                    </tr>
                </tbody>
                <!-- </draggable> -->
            </table>
        </div>
        <!-- 確認是否刪除彈窗 -->
        <my-dialog
            :show-dialog="showDialog"
            :custom-class="'top-1/4 z-[70]'"
            :custom-black-bg-class="'z-[60]'"
            @onCloseDialog="closeDialog"
        >
            <div class="w-full p-5 text-sm bg-white rounded-lg md:p-10">
                <p>確定刪除嗎？</p>
                <div class="flex justify-center mt-5">
                    <button
                        :disabled="loading"
                        class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                        @click.prevent="closeDialog"
                    >
                        關閉
                    </button>
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        @click.prevent="deleteData(deleteId)"
                    >
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
        <!-- 顯示細節彈窗 -->
        <my-dialog
            v-if="showDetailDialog"
            :show-dialog="showDetailDialog"
            :custom-class="'top-1/4 z-[70]'"
            :custom-black-bg-class="'z-[60]'"
            @onCloseDialog="closeDetailDialog"
        >
            <div class="w-full p-5 text-sm bg-white rounded-lg md:p-10">
                <h3 class="text-2xl font-bold">標題：{{ detailData.title }}</h3>
                <ul class="mt-5">
                    <li class="flex">
                        <strong class="mr-5">描述:</strong>
                        <div>{{ detailData.description }}</div>
                    </li>
                    <li class="flex">
                        <strong class="mr-5 whitespace-nowrap">網址:</strong>
                        <div class="break-all">{{ detailData.url }}</div>
                    </li>
                    <li class="flex">
                        <strong class="mr-5">排序:</strong>
                        <div>{{ detailData.sequence }}</div>
                    </li>
                </ul>
                <div class="grid grid-cols-3 gap-4 place-items-center">
                    <div class="flex col-span-1 mb-2">
                        <div class="flex-1">
                            <div>
                                <img
                                    class="w-full rounded-lg"
                                    :src="detailData.photos.logo.url"
                                    alt=""
                                />
                            </div>
                            <!-- <strong>小圖</strong> -->
                        </div>
                    </div>
                    <div class="flex col-span-2">
                        <div class="flex-1">
                            <div>
                                <img
                                    class="w-full rounded-lg"
                                    :src="detailData.photos.screenshot.url"
                                    alt=""
                                />
                            </div>
                            <!-- <strong>大圖</strong> -->
                        </div>
                    </div>
                </div>
                <div class="flex justify-center mt-5">
                    <button
                        class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                        @click.prevent="closeDetailDialog"
                    >
                        關閉
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 拖拉排序套件
import Draggable from "vuedraggable";
// 左側選單
import SideBar from "../components/SideBar.vue";
// 導入自定義彈窗組件
import myDialog from "@/components/Dialog.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const router = useRouter();
  const loading = ref(false);
  // 資料
  const datas = ref([]);
  // 顯示刪除彈窗
  const showDialog = ref(false);
  // 顯示細節彈窗
  const showDetailDialog = ref(false);
  // 刪除 id
  const deleteId = ref(null);
  // 刪除 id
  const detailData = ref({});
  /**
   * 關閉刪除彈窗
   */
  function closeDialog() {
    showDialog.value = false;
    deleteId.value = null;
  }
  /**
   * 關閉細節彈窗
   */
  function closeDetailDialog() {
    showDetailDialog.value = false;
    detailData.value = {};
  }
  /**
   * 新增按鈕
   */
  function add() {
    router.push({
      name: "add_user_experience"
    });
  }

  /**
   * 取得資料
   */
  async function getList() {
    try {
      const {
        data
      } = await proxy.$api.GetUserExperiencesListApi();
      datas.value = data.media.map(item => {
        return {
          ...item,
          description: item.description ?? ""
        };
      });
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    }
  }

  /**
   * 刪除
   */
  async function deleteData(id) {
    loading.value = true;
    try {
      await proxy.$api.DeleteUserExperienceApi(id);
      await getList();
      showDialog.value = null;
      deleteId.value = null;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "刪除失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  async function onSort(val) {
    console.log(val);
  }
  onMounted(async () => {
    await getList();
  });
  return {
    loading,
    datas,
    showDialog,
    showDetailDialog,
    deleteId,
    detailData,
    closeDialog,
    closeDetailDialog,
    add,
    deleteData
  };
};
__sfc_main.components = Object.assign({
  SideBar,
  myDialog
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
li {
    @apply mb-2;
}
</style>
